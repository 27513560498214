import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { ClientApiService } from '../../../libs/api-contract';
import { SelectFieldResult, KeyStatResult } from '@wdx/portal/api-models';
import { LocalStorageService } from '../../../libs/utils/services/local-storage.service';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root',
})
export class ClientsService {
    static CLIENT_KEY = 'CLMi-Client';

    private _clients?: SelectFieldResult[];
    private _activeClientId?: string;
    private _advisorId?: string;
    private _keyStats$?: Observable<KeyStatResult[]>;

    set clients(clients: SelectFieldResult[]) {
        this._clients = clients;
    }

    get clients() {
        return this._clients as SelectFieldResult[];
    }

    set activeClientId(activeClientId: string) {
        this._activeClientId = activeClientId;
    }

    get activeClientId() {
        return this._activeClientId as string;
    }

    get keyStats$() {
        return this._keyStats$ as Observable<KeyStatResult[]>;
    }

    set keyStats$(keyStats$: Observable<KeyStatResult[]>) {
        this._keyStats$ = keyStats$;
    }

    set advisorId(advisorId: string) {
        this._advisorId = advisorId;
    }

    get advisorId() {
        return this._advisorId as string;
    }

    constructor(
        private localStorageService: LocalStorageService,
        private clientApiService: ClientApiService,
        private userService: UserService,
    ) {
        const switchClientId = this.localStorageService.getStringKey(
            ClientsService.CLIENT_KEY,
        );
        if (switchClientId) {
            this.activeClientId = switchClientId;
        }
    }

    set(clients: SelectFieldResult[]) {
        this.clients = clients;

        const activeClientId = this.activeClientId;

        if (
            !activeClientId ||
            (activeClientId &&
                !clients
                    .map((client) => client.id)
                    .includes(this.activeClientId))
        ) {
            this.clearActiveClient();
            this.setActiveClient(clients?.[0]?.id as string);
        }
    }

    setActiveClient(activeClientId: string) {
        this.localStorageService.setStringKey(
            ClientsService.CLIENT_KEY,
            activeClientId,
        );
        this.activeClientId = activeClientId;
    }

    clearActiveClient() {
        this.localStorageService.setStringKey(
            ClientsService.CLIENT_KEY,
            undefined,
        );
    }

    getKeyStats$() {
        let keyStats$ = this.keyStats$;
        if (!keyStats$) {
            keyStats$ = this.clientApiService
                .getKeyStats(this.activeClientId)
                .pipe(shareReplay(1));
            this.keyStats$ = keyStats$;
        }
        return keyStats$;
    }

    isAdviserImpersonatingClient() {
        return (
            this.userService.isAdviser &&
            !!this.activeClientId &&
            this.activeClientId != this.advisorId
        );
    }
}
