import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    UrlTree,
} from '@angular/router';
import { Observable, map, forkJoin, take } from 'rxjs';
import { SitemapApiService } from '../../../libs/api-contract/sitemap/sitemap.service';
import { SitemapEntry } from '@wdx/portal/api-models';
import { RouteFacade } from '@wdx/shared/infrastructure/state';

export interface SiteMapGuardDataConfig {
    codes: string[];
    redirectToFirstChild?: boolean;
    redirectSibling?: string;
}

/**
 *  Needs to be used with dot separated data.codes value e.g.

    ------

    canActivate: [canActivateSitemapRoute],
    data: { sitemapConfig: { 
           codes: ['portfolios', 'portfoliostandingorders']
        }
    }

    -----

 */
export const canActivateSitemapRoute: CanActivateFn = (
    route: ActivatedRouteSnapshot,
): Observable<boolean | UrlTree> | UrlTree => {
    const sitemapService = inject(SitemapApiService);
    const router: Router = inject(Router);
    const routeFacade = inject(RouteFacade);

    if (
        !route.data?.['sitemapConfig'] ||
        !route.data?.['sitemapConfig'].codes
    ) {
        console.error(
            'canActivateSitemapRoute used without setting data.sitemapConfig on route!',
            route.url,
        );
        return router.parseUrl('/');
    }

    const { codes, redirectSibling } = route.data[
        'sitemapConfig'
    ] as SiteMapGuardDataConfig;

    return forkJoin({
        sitemap: sitemapService.getSitemap().pipe(take(1)),
        path: routeFacade.getPath$().pipe(take(1)),
        queryParms: routeFacade.getQueryParams$().pipe(
            map((queryParams) => {
                const { code, state, ...filteredQueryParams } = queryParams;
                return filteredQueryParams;
            }),
            take(1),
        ),
    }).pipe(
        map(({ sitemap, path, queryParms }) => {
            let drilldown: SitemapEntry[] | SitemapEntry | undefined = sitemap;
            codes?.forEach((code, i) => {
                const last = i === codes.length - 1;
                if (last) {
                    drilldown = (drilldown as SitemapEntry[]).find(
                        (dd) => dd.code === code,
                    );
                } else {
                    drilldown =
                        (drilldown as SitemapEntry[]).find(
                            (dd) => dd.code === code,
                        )?.children || ([] as SitemapEntry[]);
                }
            });

            const redirectUrl = redirectSibling
                ? '/' +
                  [...path.slice(0, path.length - 1), redirectSibling].join('/')
                : '/';

            if (drilldown || redirectUrl === '/' + path.join('/')) {
                return true;
            } else if (redirectSibling) {
                return router.parseUrl(redirectUrl);
            }

            const pathMatchesSitemap = (entries: SitemapEntry[]): boolean => {
                return entries.some(
                    (entry) =>
                        path.includes(entry.code ?? '') ||
                        (entry.children && pathMatchesSitemap(entry.children)),
                );
            };

            if (pathMatchesSitemap(sitemap)) {
                return true;
            }

            return router.createUrlTree(['/', sitemap[0].code], {
                queryParamsHandling: 'merge',
                queryParams: queryParms,
            });
        }),
    );
};
