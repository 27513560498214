import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WINDOW } from '@wdx/shared/utils';

export type QueryParamType = { [key: string]: string };

@Injectable({
    providedIn: 'root',
})
export class QueryParamService {
    private _window = inject(WINDOW);

    getQueryParamsFromWindow(): QueryParamType {
        const urlParams = new URLSearchParams(this._window.location.search);
        const params: QueryParamType = {};
        urlParams.forEach((value, key) => {
            params[key] = value;
        });
        return params;
    }
}
