import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ActivityExternalTask } from '@wdx/portal/api-models';
import {
    CrudStatus,
    ExternalTaskStage,
    ExternalTaskStatusType,
    WdxThemeColor,
} from '@wdx/shared/utils';
import { Observable, filter, map, switchMap } from 'rxjs';
import * as externalTasksActions from './external-tasks.actions';
import * as externalTasksSelectors from './external-tasks.selectors';
import { ExternalTaskState } from './external-tasks.reducer';
import * as R from 'ramda';

export type ChecklistFilters = 'active' | 'completed';
export type ChecklistHideOptions = 'prev' | 'next' | 'all';
export type HideOptionalParam = {
    hide?: ChecklistHideOptions;
    filter: ChecklistFilters;
};

export interface ListConfig {
    badgeTheme: WdxThemeColor;
    statusLabel: string;
    tooltipLabel: string;
    routerLink: string;
    optionalParams?: HideOptionalParam;
}

export type TaskConfig = ActivityExternalTask & ListConfig;

export type GroupedTask = {
    stage: ExternalTaskStage;
    tasks: TaskConfig[];
    translationKey: string;
};

@Injectable()
export class ExternalTasksFacade {
    constructor(private store$: Store<ExternalTaskState>) {}

    getTasks$(): Observable<ActivityExternalTask[]> {
        return this.store$.pipe(
            select(externalTasksSelectors.selectStatus),
            switchMap((status) => {
                if (status < CrudStatus.Loading) {
                    this.loadTasks();
                }
                return this.store$.pipe(
                    select(externalTasksSelectors.selectTasks),
                    filter(Boolean),
                    map((tasks) => {
                        const sortedArray = [...tasks];
                        sortedArray.sort(
                            (a, b) => (a.order || 0) - (b.order || 0),
                        );
                        return sortedArray;
                    }),
                );
            }),
        );
    }

    loadTasks(): void {
        this.store$.dispatch(externalTasksActions.loadExternalTasks());
    }

    getGroupedTasks$(): Observable<GroupedTask[]> {
        let groups: GroupedTask[] = [
            {
                stage: ExternalTaskStage.BeforeYouStart,
                translationKey: `PORTAL_CHECKLIST_${ExternalTaskStage.BeforeYouStart.toUpperCase()}_LABEL`,
                tasks: [],
            },
            {
                stage: ExternalTaskStage.PrepareYourApplication,
                translationKey: `PORTAL_CHECKLIST_${ExternalTaskStage.PrepareYourApplication.toUpperCase()}_LABEL`,
                tasks: [],
            },
            {
                stage: ExternalTaskStage.AdHoc,
                translationKey: `PORTAL_CHECKLIST_${ExternalTaskStage.AdHoc.toUpperCase()}_LABEL`,
                tasks: [],
            },
            {
                stage: ExternalTaskStage.Review,
                translationKey: `PORTAL_CHECKLIST_${ExternalTaskStage.Review.toUpperCase()}_LABEL`,
                tasks: [],
            },
            {
                stage: ExternalTaskStage.CompleteAndSign,
                translationKey: `PORTAL_CHECKLIST_${ExternalTaskStage.CompleteAndSign.toUpperCase()}_LABEL`,
                tasks: [],
            },
            {
                stage: ExternalTaskStage.NotSpecified,
                translationKey: `PORTAL_CHECKLIST_${ExternalTaskStage.NotSpecified.toUpperCase()}_LABEL`,
                tasks: [],
            },
        ];

        return this.getTasks$().pipe(
            map((allTasks) => {
                groups = groups
                    .map((group) => {
                        const tasks = allTasks.filter(
                            (task) => task?.stage === group.stage,
                        ) as TaskConfig[];
                        return {
                            ...group,
                            tasks,
                        };
                    })
                    .filter((group) => group.tasks.length);
                groups.forEach((group) => {
                    group.tasks = R.sortBy(R.prop('order'))(group.tasks);
                });

                return groups;
            }),
        );
    }

    getFlatTaskList$() {
        return this.getGroupedTasks$().pipe(
            map((groups) => groups.flatMap(({ tasks }) => tasks)),
        );
    }

    filterTasksByCompleted(
        tasks: TaskConfig[],
        completed: boolean,
    ): TaskConfig[] {
        return tasks.filter(
            (task) =>
                completed ===
                [
                    ExternalTaskStatusType.Confirmed,
                    ExternalTaskStatusType.Submitted,
                ].includes(task.externalStatus as ExternalTaskStatusType),
        );
    }
}
