<wdx-nav-wrapper role="navigation">
    @for (navigationItem of navigationItems; track navigationItem) {
        <cp-navigation-item
            [navigationItem]="navigationItem"
            [showLabel]="showLabel"
            (navClick)="navClicked()"
            [ngClass]="{
                'bg-strong border-bottom border-dark border-3':
                    navigationItem.isHeader,
            }"
        ></cp-navigation-item>

        @if (navigationItem.children?.length) {
            @for (childItem of navigationItem.children; track childItem) {
                <cp-navigation-item
                    [navigationItem]="childItem"
                    [showLabel]="showLabel"
                    [isChild]="true"
                    (navClick)="navClicked()"
                ></cp-navigation-item>
            }
        }
    }
</wdx-nav-wrapper>
