import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { SitemapEntry } from '@wdx/portal/api-models';
import { Observable, map, shareReplay } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { BaseApiService } from '../base-api.service';
import { UrlSegment } from './sitemap-url-segment';
import { ClientsService } from '../../../core/services/clients/clients.service';

export type SiteMapCode =
    | 'insights'
    | 'portfolios'
    | 'clients'
    | 'messages'
    | 'documents'
    | 'team'
    | 'todo'
    | 'client';

@Injectable({ providedIn: 'root' })
export class SitemapApiService extends BaseApiService {
    constructor(
        http: HttpClient,
        apiConfig: ApiConfigService,
        private clientsService: ClientsService,
    ) {
        super(http, apiConfig);
    }

    private sitemap$?: Observable<SitemapEntry[]>;

    getSitemap(): Observable<SitemapEntry[]> {
        if (!this.sitemap$) {
            this.sitemap$ = this.get<SitemapEntry[]>(
                `${UrlSegment.Sitemap}`,
            ).pipe(
                map((sitemap) => {
                    if (this.clientsService.isAdviserImpersonatingClient()) {
                        const clientEntry = sitemap.find(
                            (entry) => entry.code === 'client',
                        );
                        if (clientEntry && clientEntry.children) {
                            return [clientEntry];
                        }
                    }
                    return sitemap;
                }),
                shareReplay(1),
            );
        }
        return this.sitemap$;
    }

    hasFeature(feature: SiteMapCode) {
        return this.getSitemap().pipe(
            map((sitemap) => this.checkFeatureRecursive(sitemap, feature)),
        );
    }

    private checkFeatureRecursive(
        entries: SitemapEntry[],
        feature: SiteMapCode,
    ): boolean {
        return entries.some(
            (entry) =>
                entry.code === feature ||
                (entry.children &&
                    this.checkFeatureRecursive(entry.children, feature)),
        );
    }

    getEntryLabel$(
        labelKey: string,
        fallbackLabel: string,
    ): Observable<string> {
        return this.getSitemap().pipe(
            map((sitemap) =>
                this.getEntryLabel(sitemap, labelKey, fallbackLabel),
            ),
        );
    }

    getEntryLabel(
        sitemap: SitemapEntry[],
        labelKey: string,
        fallbackLabel: string,
    ): string {
        return (
            sitemap.find((entry) => entry.code === labelKey)?.displayName
                ?.value || fallbackLabel
        );
    }
}
