import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { QueryParamService } from '../../shared/services/query-param/query-param.service';

export const customAuthGuard: CanActivateFn = (
    route,
): Observable<boolean | UrlTree> => {
    const auth = inject(AuthService);
    const router = inject(Router);
    const queryParamService = inject(QueryParamService);

    return auth.isAuthenticated$.pipe(
        map(
            (isAuthenticated) =>
                isAuthenticated ||
                router.createUrlTree(['/login'], {
                    queryParams: queryParamService.getQueryParamsFromWindow(),
                    queryParamsHandling: 'merge',
                }),
        ),
    );
};
