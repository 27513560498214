import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SitemapEntry } from '@wdx/portal/api-models';
import { WdxNavModule } from '@wdx/shared/components/wdx-nav';
import { NavigationMenu } from './navigation.model';
import { NAV_MENU } from './navigation.static.data';
import { NavigationItemComponent } from './navigation-item/navigation-item.component';

@Component({
    selector: 'cp-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        WdxNavModule,
        NavigationItemComponent,
    ],
})
export class NavigationComponent {
    @Input() showLabel = true;
    @Input() set sitemap(sitemap: SitemapEntry[]) {
        this.navigationItems = this.transformSitemap(sitemap);
    }
    @Output() navClick = new EventEmitter<void>();

    public navigationItems: NavigationMenu[] = [];

    navClicked(): void {
        this.navClick.emit();
    }

    private transformSitemap(sitemap: SitemapEntry[]): NavigationMenu[] {
        return sitemap
            .map((sItem) => {
                const menuItem = NAV_MENU.find(
                    (mItem) => mItem.sitemapCode === sItem.code,
                );

                if (!menuItem) {
                    return undefined;
                }

                return {
                    ...menuItem,
                    label:
                        sItem.displayName?.value ||
                        sItem.displayName?.parameters?.['name'] ||
                        menuItem.label,
                    icon: sItem.icon || menuItem.icon,
                    visible: true,
                    children: sItem.children
                        ? this.transformSitemap(sItem.children)
                        : [],
                } as NavigationMenu;
            })
            .filter(Boolean) as NavigationMenu[];
    }
}
