import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as configDataActions from './config-data.actions';
import { CountryApiService } from '../../libs/api-contract/country/country.service';
import { UserApiService } from '../../libs/api-contract';

@Injectable()
export class ConfigDataEffects {
    private countryApiService = inject(CountryApiService);
    private userApiService = inject(UserApiService);

    private actions$ = inject(Actions);

    loadCountries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(configDataActions.loadCountries),
            switchMap(() =>
                this.countryApiService.getCountries().pipe(
                    map((countries) =>
                        configDataActions.loadCountriesSuccess({
                            countries,
                        }),
                    ),
                    catchError((error) => {
                        return of(configDataActions.loadCountriesFailure());
                    }),
                ),
            ),
        ),
    );

    loadLocale$ = createEffect(() =>
        this.actions$.pipe(
            ofType(configDataActions.loadLocale),
            switchMap(() =>
                this.userApiService.getLocale().pipe(
                    map((locale) =>
                        configDataActions.loadLocaleSuccess({
                            locale,
                        }),
                    ),
                    catchError((error) => {
                        return of(configDataActions.loadLocaleFailure());
                    }),
                ),
            ),
        ),
    );
}
