import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { FaviconService } from '@wdx/shared/utils';
import { Observable, switchMap, take, tap } from 'rxjs';
import { PortalTenantService } from '../../../core/services/tenants/tenants.service';
import { ApiConfigService } from '../../../libs/api-contract/api-config.service';
import { BaseApiService } from '../../../libs/api-contract/base-api.service';
import { RouteFacade } from '@wdx/shared/infrastructure/state';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService extends BaseApiService {
    constructor(
        http: HttpClient,
        apiConfig: ApiConfigService,
        private auth0Service: AuthService,
        private faviconService: FaviconService,
        private portalTenantService: PortalTenantService,
        private routeFacade: RouteFacade,
    ) {
        super(http, apiConfig);
    }

    login(options?: { returnToUrl?: string }): Observable<void> {
        return this.getTenantDetails().pipe(
            tap(({ tenantCode }) =>
                this.faviconService.changeFavicon(tenantCode),
            ),
            tap(
                ({ tenantCode }) =>
                    (this.portalTenantService.tenantCode =
                        tenantCode.toLowerCase()),
            ),
            switchMap(({ organisationIdentifier }) =>
                this.loginWithRedirect(organisationIdentifier, options),
            ),
        );
    }

    getTenantDetails(): Observable<{
        organisationIdentifier: string;
        tenantCode: string;
    }> {
        return this.get<{ organisationIdentifier: string; tenantCode: string }>(
            `system/tenant/auth0/organisationidentifier?url=${encodeURIComponent(
                window.location.host,
            )}`,
        );
    }

    loginWithRedirect(
        organisationIdentifier: string,
        options?: { returnToUrl?: string },
    ): Observable<void> {
        return this.routeFacade.getQueryParams$().pipe(
            take(1),
            switchMap((queryParams) => {
                return this.auth0Service.loginWithRedirect({
                    authorizationParams: {
                        redirect_uri: window.location.origin,
                        organization: organisationIdentifier,
                    },
                    appState: {
                        target: '/dashboard',
                        queryParams: queryParams,
                        ...(options?.returnToUrl && {
                            returnToUrl: options.returnToUrl,
                        }),
                    },
                });
            }),
        );
    }
}
