import { NavigationMenu } from './navigation.model';

export const NAV_MENU: NavigationMenu[] = [
    {
        code: 'dashboard',
        routerLink: ['/dashboard'],
        routerLinkExact: false,
        label: 'Dashboard',
        icon: 'home',
        sitemapCode: 'dashboard',
    },
    {
        code: 'todo',
        routerLink: ['/checklist'],
        label: 'Checklist',
        icon: 'list-check',
        sitemapCode: 'todo',
        visible: true,
    },
    {
        code: 'team',
        routerLink: ['/team'],
        label: 'Team',
        icon: 'people-group',
        sitemapCode: 'team',
    },
    {
        code: 'messages',
        routerLink: ['/messages'],
        label: 'Messages',
        icon: 'inbox',
        sitemapCode: 'messages',
    },
    {
        code: 'portfolios',
        routerLink: ['/portfolios'],
        label: 'Portfolios',
        icon: 'briefcase',
        sitemapCode: 'portfolios',
    },
    {
        code: 'documents',
        routerLink: ['/documents'],
        label: 'Documents',
        icon: 'file-lines',
        sitemapCode: 'documents',
    },
    {
        code: 'insights',
        routerLink: ['/insights'],
        label: 'Insights',
        icon: 'newspaper',
        sitemapCode: 'insights',
    },
    {
        code: 'clients',
        routerLink: ['/clients'],
        label: 'Clients',
        icon: 'vault',
        sitemapCode: 'clients',
    },
    {
        code: 'client',
        routerLink: [],
        label: 'Clients',
        icon: 'vault',
        sitemapCode: 'client',
        isHeader: true,
    },
];
