import { Injectable, inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {
    EMPTY,
    Observable,
    catchError,
    map,
    tap,
    switchMap,
    filter,
    forkJoin,
    take,
} from 'rxjs';
import {
    SystemApiService,
    ClientApiService,
    UserApiService,
} from '../../libs/api-contract';
import { ThemeService } from '../../libs/system/theme';
import { AppService } from '../services/app/app.service';
import { ClientsService } from '../services/clients/clients.service';
import { IdleService } from '../services/idle/idle.service';
import { UserService } from '../services/user/user.service';
import { SitemapApiService } from '../../libs/api-contract/sitemap/sitemap.service';
import { AuthService } from '@auth0/auth0-angular';
import {
    DisableTranslationType,
    DISABLE_TRANSLATIONS_QUERY_PARAM,
    FaviconService,
    TranslationsService,
} from '@wdx/shared/utils';
import { RouteFacade } from '@wdx/shared/infrastructure/state';
import { ConfigService } from '../services/config/config.service';
import { LoadScriptService } from '../services/load-script/load-script.service';

@Injectable({
    providedIn: 'root',
})
export class AppGuard implements CanActivate {
    private sitemapService = inject(SitemapApiService);
    private router = inject(Router);
    private idleService = inject(IdleService);
    private systemApiService = inject(SystemApiService);
    private clientApiService = inject(ClientApiService);
    private userApiService = inject(UserApiService);
    private clientsService = inject(ClientsService);
    private themeService = inject(ThemeService);
    private userService = inject(UserService);
    private appService = inject(AppService);
    private auth = inject(AuthService);
    private faviconService = inject(FaviconService);
    private translationsService = inject(TranslationsService);
    private routeFacade = inject(RouteFacade);
    private configService = inject(ConfigService);
    private loadScriptService = inject(LoadScriptService);

    canActivate(): Observable<boolean> {
        this.appService.isReady$.next(false);

        if (!this.appService.hasRedirectedBefore()) {
            this.handleAppStateRedirect();
        }

        return this.auth.isLoading$.pipe(
            filter((loading) => !loading),
            switchMap(() => {
                return this.clientApiService.getSelection().pipe(
                    tap((clients) => {
                        if (!clients?.length) {
                            this.router.navigate(['no-clients']);
                            this.appService.isReady$.next(true);
                        }
                        if (this.userService.isAdviser) {
                            const clientId = clients?.[0]?.id ?? '';
                            this.clientsService.advisorId = clientId;
                            // this will only set it if it hasnt been set by the query param from the app state
                            // but this needs to be set for things like client list
                            this.clientsService.activeClientId =
                                this.clientsService.activeClientId ?? clientId;

                            return;
                        }
                        this.clientsService.set(clients);
                    }),
                    switchMap(() =>
                        forkJoin({
                            _: this.sitemapService.getSitemap(),
                            me: this.userApiService.getMe(),
                            settings: this.systemApiService.getSettings(),
                            locale: this.userApiService.getLocale(),
                            theme: this.systemApiService.getTheme(),
                            translations:
                                this.systemApiService.getTranslations(),
                            disableTranslations: this.routeFacade
                                .getQueryParam$(
                                    DISABLE_TRANSLATIONS_QUERY_PARAM,
                                )
                                .pipe(take(1)),
                        }).pipe(
                            tap((res) => {
                                this.configService.systemSettings =
                                    res.settings;

                                const unbluUrl = res.settings.Unblu?.libraryUrl;
                                if (unbluUrl) {
                                    this.loadScriptService.loadScript(unbluUrl);
                                }

                                // Set up eagerly required data
                                this.userService.set(res.me, res.locale);
                                this.themeService.set(res.theme);

                                this.idleService.startWatching(
                                    res.settings.Timeout || 1200,
                                );

                                // Notify app is ready
                                this.appService.isReady$.next(true);

                                // Set dynamic favicon
                                this.faviconService.loadFavicon();

                                this.translationsService.set(
                                    res.translations,
                                    res.disableTranslations as DisableTranslationType,
                                );
                            }),
                            map(() => true),
                            catchError((error) => {
                                console.error(error);
                                this.router.navigate(['/error']);
                                this.appService.isReady$.next(true);
                                return EMPTY;
                            }),
                        ),
                    ),
                    catchError(() => {
                        this.router.navigate(['no-clients']);
                        this.appService.isReady$.next(true);
                        return EMPTY;
                    }),
                );
            }),
        );
    }

    handleAppStateRedirect() {
        this.auth.appState$
            .pipe(
                take(1),
                filter(
                    (appState) => Object.keys(appState.queryParams).length > 0,
                ),
            )
            .subscribe((appState) => {
                const targetUrl = appState.target;
                const queryParams = appState.queryParams;

                const queryClientId = queryParams?.['clientId'];
                if (this.userService.isAdviser && queryClientId) {
                    this.clientsService.activeClientId = queryClientId;
                }
                this.router.navigate([targetUrl], {
                    queryParams: queryParams,
                    queryParamsHandling: 'merge',
                });
                this.appService.setRedirected();
            });
    }
}
