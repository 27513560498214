<wdx-nav-item>
    @if (navigationItem.isHeader) {
        <span
            class="text-decoration-none nav-item-header d-flex align-items-center justify-content-start"
        >
            <wdx-icon
                class="me-2"
                [iconStyle]="'fal'"
                [icon]="navigationItem.icon"
                [fixedWidth]="true"
                [scale]="2"
            ></wdx-icon>

            @if (showLabel) {
                <span class="fs-5 fw-bold">{{ navigationItem.label }}</span>
            }
        </span>
    } @else {
        <a
            #routerLinkActive="routerLinkActive"
            [routerLink]="navigationItem.routerLink"
            [routerLinkActiveOptions]="{
                exact: !!navigationItem.routerLinkExact,
            }"
            routerLinkActive="active"
            wdxNavLink
            (click)="handleClick()"
        >
            <wdx-icon
                class="me-2"
                [iconStyle]="routerLinkActive.isActive ? 'fas' : 'fal'"
                [icon]="navigationItem.icon"
                [fixedWidth]="true"
                [scale]="2"
                [class.ms-4]="isChild"
            ></wdx-icon>

            @if (showLabel) {
                <span class="fs-5 fw-bold">{{ navigationItem.label }}</span>
            }
        </a>
    }
</wdx-nav-item>
