import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import * as fileDownloadActions from './file-download.actions';
import * as fileDownloadSelectors from './file-download.selectors';
import { FileDownloadService } from './file-download.service';
import { FileDownloadStoreSlice } from './file-download.reducer';
import { WINDOW } from '@wdx/shared/utils';

@Injectable()
export class FileDownloadEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<FileDownloadStoreSlice>);
    private fileDownloadService = inject(FileDownloadService);
    private _window = inject(WINDOW) as Window & {
        URL: { createObjectURL(obj: Blob | MediaSource): string };
    };
    getFilePreview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fileDownloadActions.getFilePreview),
            mergeMap((action) =>
                this.fileDownloadService
                    .getFile(action.fileIndex.fileIndex as string)
                    .pipe(
                        map((response) => {
                            const blob = new Blob([response.body as Blob], {
                                type: action.fileIndex.contentType as string,
                            });
                            const fileUrl = window.URL.createObjectURL(blob);
                            return fileDownloadActions.getFilePreviewSuccess({
                                fileIndex: action.fileIndex,
                                fileType: action.fileIndex
                                    .contentType as string,
                                fileUrl,
                            });
                        }),
                        catchError((error) => {
                            return of(
                                fileDownloadActions.getFilePreviewFailure({
                                    fileIndex: action.fileIndex,
                                    error,
                                }),
                            );
                        }),
                    ),
            ),
        ),
    );

    destroyFilePreview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fileDownloadActions.destroyFilePreview),
            switchMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store$.select(
                            fileDownloadSelectors.getFilePreview,
                            {
                                id: action.fileIndex,
                            },
                        ),
                    ),
                ),
            ),
            map(([action, filePreview]) => {
                if (filePreview?.fileUrl) {
                    window.URL.revokeObjectURL(filePreview.fileUrl);
                }
                return fileDownloadActions.destroyFilePreviewSuccess({
                    fileIndex: action.fileIndex,
                });
            }),
        ),
    );

    deleteFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fileDownloadActions.deleteFile),
            mergeMap((action) =>
                this.fileDownloadService.deleteFile(action.fileIndex).pipe(
                    map(() => {
                        return fileDownloadActions.deleteFileSuccess({
                            fileIndex: action.fileIndex,
                        });
                    }),
                    catchError((error) => {
                        return of(
                            fileDownloadActions.deleteFileFailure({
                                fileIndex: action.fileIndex,
                                error,
                            }),
                        );
                    }),
                ),
            ),
        ),
    );

    getFileDownload$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fileDownloadActions.getFileDownload),
                mergeMap((action) =>
                    this.fileDownloadService
                        .getFile(action.fileIndex)
                        .pipe(
                            tap((response) =>
                                this.fileDownloadService.downloadFile(
                                    action.fileName as string,
                                    response,
                                ),
                            ),
                        ),
                ),
            ),
        { dispatch: false },
    );

    getContextualFileDownload$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fileDownloadActions.getContextualFileDownload),
                mergeMap((action) =>
                    this.fileDownloadService
                        .getContextualFile(action.queryEntity, action.id)
                        .pipe(
                            tap((response) =>
                                this.fileDownloadService.downloadFile(
                                    action.fileName as string,
                                    response,
                                ),
                            ),
                        ),
                ),
            ),
        { dispatch: false },
    );

    getContextualFilePreview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fileDownloadActions.getContextualFilePreview),
            mergeMap((action) =>
                this.fileDownloadService
                    .getContextualFile(action.queryEntity, action.id)
                    .pipe(
                        map((response) => {
                            const blob = new Blob([response.body as Blob], {
                                type: action.fileType,
                            });
                            const fileUrl =
                                this._window.URL.createObjectURL(blob);
                            return fileDownloadActions.getContextualFilePreviewSuccess(
                                {
                                    queryEntity: action.queryEntity,
                                    id: action.id,
                                    fileUrl,
                                    fileType: action.fileType,
                                },
                            );
                        }),
                        catchError((error) =>
                            of(
                                fileDownloadActions.getContextualFilePreviewFailure(
                                    {
                                        id: action.id,
                                        error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );
}
