import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    private hasRedirected = false;

    isReady$ = new BehaviorSubject<boolean>(true);
    tenantName$ = new BehaviorSubject<string>('');

    setRedirected() {
        this.hasRedirected = true;
    }

    hasRedirectedBefore(): boolean {
        return this.hasRedirected;
    }
}
