import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGetConfig } from '@wdx/shared/utils';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';

@Injectable()
export class FileDownloadService {
    constructor(
        private http: HttpClient,
        private config: IGetConfig,
    ) {}

    getFile(fileIndex: string): Observable<HttpResponse<Blob>> {
        return this.http.get(
            `${this.config.getConfiguration().API_BASE}/file/${fileIndex}`,
            {
                observe: 'response',
                responseType: 'blob',
            },
        );
    }

    getTextFileContent(fileIndex: string): Observable<string> {
        return this.http.get(
            `${this.config.getConfiguration().API_BASE}/file/${fileIndex}`,
            {
                observe: 'body',
                responseType: 'text',
            },
        );
    }

    deleteFile(fileIndex: string) {
        return this.http.delete(
            `${this.config.getConfiguration().API_BASE}/file/${fileIndex}`,
            {
                params: { failSilently: true },
            },
        );
    }

    downloadFile(name: string, response: HttpResponse<Blob>) {
        const blob = new Blob([response.body as Blob], {
            type: response.body?.type,
        });
        saveAs(blob, name);
    }

    getContextualFile(
        queryEntity: string,
        id: string,
    ): Observable<HttpResponse<Blob>> {
        return this.http.get(
            `${this.config.getConfiguration().API_BASE}/${queryEntity}/${id}/file`,
            {
                observe: 'response',
                responseType: 'blob',
            },
        );
    }
}
