import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { NavigationMenu } from '../navigation.model';
import { WdxNavModule } from '@wdx/shared/components/wdx-nav';

@Component({
    selector: 'cp-navigation-item',
    templateUrl: './navigation-item.component.html',
    styleUrls: ['./navigation-item.component.scss'],
    standalone: true,
    imports: [CommonModule, WdxNavModule, RouterModule, WdxIconModule],
})
export class NavigationItemComponent {
    @Input() navigationItem!: NavigationMenu;
    @Input() isChild = false;
    @Input() showLabel = true;
    @Output() navClick = new EventEmitter<void>();

    handleClick(): void {
        this.navClick.emit();
    }
}
