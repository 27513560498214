/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ExternalTaskStage {
  NotSpecified = 'NotSpecified',
  BeforeYouStart = 'BeforeYouStart',
  PrepareYourApplication = 'PrepareYourApplication',
  CompleteAndSign = 'CompleteAndSign',
  Review = 'Review',
  AdHoc = 'AdHoc'
}
